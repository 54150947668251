export const MATERIALS_LIST_TYPE = '_ORBIS_MATERIALS_LIST_TYPE';
export const ORDERS_FILTER_VALUES = '_ORBIS_ORDERS_FILTER_VALUES';
export const ORDERS_SELECTED_FILTERS = '_ORBIS_ORDERS_SELECTED_FILTERS';
export const MATERIALS_TABLE_COLUMNS = '_ORBIS_MATERIALS_TABLE_COLUMNS';
export const ORDERS_TABLE_COLUMNS = '_ORBIS_ORDERS_TABLE_COLUMNS';
export const ORBIS_ORDERS_LIST_VIEW = '_ORBIS_ORDERS_LIST_VIEW';
export const ORBIS_ACTIVE_PROCESS = '_ORBIS_ACTIVE_PROCESS';
export const WAREHOUSES_TABLE_COLUMNS = '_ORBIS_WAREHOUSES_TABLE_COLUMNS';
export const CATALOG_MATERIALS_TABLE_COLUMNS = '_ORBIS_CATALOG_MATERIALS_TABLE_COLUMNS';
export const ACCOUNT_SETTINGS_ACTIVE_TAB = '_ORBIS_ACCOUNT_SETTINGS_ACTIVE_TAB';
