import React, { FC, useState } from 'react';

import { Spin } from 'antd';

import { ColumnsType } from 'antd/es/table';
import { isEmpty } from 'lodash';

import { useTranslation } from 'react-i18next';
import type { ResizeCallbackData } from 'react-resizable';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { RetrieveOrderSchema } from '@app/models/orders';
import { ORDERS_FILTER_VALUES, ORDERS_TABLE_COLUMNS } from '@app/services/appSettings';
import { TableDnDResizable } from '@components/TableDnDResizable';
import { TableEditMenu } from '@components/TableEditMenu';
import { getSortValues } from '@pages/Processes/pages/Orders/pages/List/components/TableContainer/data/getSortValues';
import { getSorterFlag } from '@pages/Processes/pages/Orders/pages/List/components/TableContainer/data/getSorterFlag';

import { useGetOrdersByProcessQuery, useGetProcessQuery } from '../../../../../../hooks';

import { useProcessDataParams } from '../../../../../../providers';
import { Filters } from '../../../../components/Filters';

import { RowsActions } from './components/RowsActions';
import { getFilters } from './data/getFilters';
import { getTableItems } from './data/getTableItems';
import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

export const TableContainer: FC = () => {
  const params = useParams<{ processId: string }>();
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const commonT = useTranslation(NSKeys.common);
  const ordersT = useTranslation(NSKeys.orders);
  const [selectedRows, setSelectedRows] = React.useState<{ uid: string; version: string }[]>([]);
  const [query, setQuery] = React.useState<string>('');
  const [sorting, setSorting] = React.useState<string[]>([]);
  const [baseColumns, setBaseColumns] = useState<ColumnsType<RetrieveOrderSchema> | undefined>();
  const [localStorageColumns, setLocalStorageColumns] = useLocalStorage<ColumnsType<RetrieveOrderSchema> | undefined>(
    `${params.processId}${ORDERS_TABLE_COLUMNS}`,
    undefined
  );
  // const [localStorageFilterValues] = useLocalStorage(`${params.processId || ''}${ORDERS_FILTER_VALUES}`, {});
  const { data: processData, isLoading: isProcessLoading } = useGetProcessQuery(params.processId);
  const { data: tableData, isLoading } = useGetOrdersByProcessQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
    process_uid: params.processId,
    sort: sorting,
    query,
  });
  const { setQueryParams, resetQueryParams } = useProcessDataParams();

  React.useEffect(() => {
    setQueryParams({ sort: sorting, query });
  }, [sorting, query]);

  React.useEffect(() => {
    return () => {
      resetQueryParams();
    };
  }, []);

  React.useEffect(() => {
    if (processData) {
      const data = getTableItems({ processData, splitFields: true, t: ordersT.t });
      setBaseColumns(data);
      if (!localStorageColumns && data) {
        setLocalStorageColumns(data.map(({ render, ...rest }) => rest));
      }
    }
  }, [processData]);

  const filters = React.useMemo(() => {
    if (processData && params.processId) {
      const columns = getTableItems({ processData, splitFields: false, t: ordersT.t });
      return columns?.length ? getFilters(columns, commonT.t, params.processId) : [];
    }
  }, [processData, processData, params.processId]);

  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...(localStorageColumns || [])];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width > 20 ? size.width : 20,
      };
      setLocalStorageColumns(newColumns);
    };

  const handleTableHeaderChange = (checkedValues: any[]) => {
    baseColumns &&
      setLocalStorageColumns(
        baseColumns
          .map((col) => ({
            ...col,
            width: localStorageColumns?.find((localEl) => localEl.key === col.key)?.width || col.width,
          }))
          .filter((item) => checkedValues.includes(item.key as string))
      );
  };

  if (!baseColumns || !localStorageColumns) {
    return <Spin />;
  }

  return (
    <Container style={{ position: 'relative' }}>
      {!!selectedRows.length && <RowsActions selectedRows={selectedRows} onDone={() => setSelectedRows([])} />}
      <Filters onChange={setQuery} fields={filters || []} uidPrefix={params?.processId} />
      <TableDnDResizable<RetrieveOrderSchema>
        localStorageName={`${params.processId}${ORDERS_TABLE_COLUMNS}`}
        columns={[
          ...localStorageColumns.map((el, index) => ({
            ...el,
            sorter: getSorterFlag(el, index),
            onHeaderCell: (column: any) => ({
              width: column.width,
              onResize: handleResize(index) as React.ReactEventHandler<any>,
            }),
            render: baseColumns?.find((baseCol) => baseCol.key === el.key)?.render,
          })),
        ]}
        actionColumn={[
          {
            title: (
              <TableEditMenu
                defaultValue={localStorageColumns?.map((el) => el.key as string)}
                handleOnChange={handleTableHeaderChange}
                options={baseColumns?.map((el) => ({
                  value: el.key as string,
                  label: el.title as string,
                }))}
              />
            ),
            key: 'action',
            fixed: 'right',
            align: 'center',
            width: 40,
          },
        ]}
        onChange={(pagination, filters, sorter) => {
          setSorting(getSortValues(sorter as any));
          setSelectedRows([]);
          setTableParams({
            pagination,
            filters,
            ...sorter,
          });
        }}
        showSorterTooltip
        pagination={{
          ...tableParams.pagination,
          total: tableData?.total,
          showQuickJumper: true,
          showSizeChanger: true,
          position: ['bottomCenter'],
          showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
        }}
        key="uid"
        rowKey="uid"
        onColumnsChange={setLocalStorageColumns}
        rowClassName="cursorPointer"
        className="tableClassname"
        loading={isLoading}
        dataSource={tableData?.items}
        style={{ marginTop: 24 }}
        scroll={{ x: 'max-content' }}
        onRow={(data) => {
          return {
            onClick: (e) => data?.$permissions?.view && navigate(`${data.uid}`),
          };
        }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedRows.map((el) => el.uid),
          renderCell: (value, record, index, originNode) => (
            <div onClick={(e) => e.stopPropagation()}>{originNode}</div>
          ),
          onChange: (selectedRowKeys, data) => {
            setSelectedRows(
              data?.map((el) => {
                return { uid: el.uid, version: el.version };
              }) as any[]
            );
          },
        }}
      />
    </Container>
  );
};
