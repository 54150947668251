import React, { FC } from 'react';

import { Button, Flex, Form, Layout, message, Row, Space, Spin, Steps } from 'antd';
import type { UploadProps } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { Upload } from '@components/AntFields';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { PageTitle } from '@components/PageTitle';

import { usePostGetSettingsMapping, usePostImportsPreview } from '@pages/Catalogs/hooks';

import { PreviewForm } from './components/PreviewForm';
import { Tables } from './components/Tables';
// import { TABLE_DATA } from './constants';
import { IFormValues } from './interfaces';
import { Container } from './styles';

export const OrderImport: FC = () => {
  const params = useParams<{ id: string }>();
  const orderImportsT = useTranslation(NSKeys.orderImports);
  const navigate = useNavigate();
  const [previewForm] = Form.useForm();
  const [settingsForm] = Form.useForm();
  const [fileUid, setFileUid] = React.useState('');
  const commonT = useTranslation(NSKeys.common);
  const { mutate: getPreview, data: previewData, status: previewStatus, reset } = usePostImportsPreview();
  const { mutate: getSettings, data: settingsData, isLoading: settingsIsLoading } = usePostGetSettingsMapping();

  const [currentStep, setCurrentStep] = React.useState(0);
  const [maxAvailableStep, setMaxAvailableStep] = React.useState(0);

  const goBack = () => navigate('/dashboard/catalogs');

  const onChange = (value: number) => {
    setCurrentStep(value);
  };

  React.useEffect(() => {
    setMaxAvailableStep(fileUid ? 1 : 0);
    previewForm.setFieldValue('uploaded_file_uid', fileUid);
    reset();
  }, [fileUid]);

  React.useEffect(() => {
    if (params.id) {
      getSettings({ catalog_uid: params.id });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (previewData && previewStatus === 'success') {
      setCurrentStep(2);
      setMaxAvailableStep(2);
    }
  }, [previewData, previewStatus]);

  React.useEffect(() => {
    setCurrentStep(maxAvailableStep);
  }, [maxAvailableStep]);

  const onRemoveFile = () => setFileUid('');

  const handleChange: UploadProps['onChange'] = (info) => {
    const status = info.file.status;

    if (status === 'done' && info?.file?.response?.uploaded_file_uid) {
      setFileUid(info?.file?.response?.uploaded_file_uid);
    } else if (status === 'error') {
      message.error(commonT.t('error400'));
    }
  };

  const handlePreview = (formValues: IFormValues) => {
    getPreview(formValues);
  };

  const previewFormValues: IFormValues = previewForm.getFieldsValue();

  const getDisplayValue = (index: number) => {
    return currentStep === index ? 'block' : 'none';
  };

  const STEPS = [
    {
      title: orderImportsT.t('createOrderImportStep1Title'),
      description: orderImportsT.t('createOrderImportStep1Description'),
    },
    {
      title: orderImportsT.t('createOrderImportStep2Title'),
      description: orderImportsT.t('createOrderImportStep2Description'),
    },
    {
      title: orderImportsT.t('createOrderImportStep3Title'),
      description: orderImportsT.t('createOrderImportStep3Description'),
    },
  ];

  if (settingsIsLoading) {
    return (
      <Flex style={{ width: '100%', minHeight: '400px' }} justify="center" align="center">
        <Spin />
      </Flex>
    );
  }

  return (
    <>
      <Layout.Header>
        <Breadcrumbs />
        <Row justify="space-between" align="middle">
          <PageTitle title={orderImportsT.t('createOrderImportTitle')} onClick={goBack} />
          <Space size="middle">
            <Button onClick={goBack} size="middle">
              {commonT.t('buttonCancel')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>

      <Container>
        <Flex vertical gap={16}>
          <Steps
            key={maxAvailableStep}
            current={currentStep}
            onChange={onChange}
            items={STEPS.map(({ title, description }, i) => ({
              title,
              description,
              disabled: i > maxAvailableStep,
            }))}
          />

          <div style={{ display: getDisplayValue(0) }}>
            <Upload
              apiPath="orders/order-imports/upload"
              listType="text"
              name="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              multiple={false}
              maxCount={1}
              description=" "
              onChange={handleChange}
              onRemove={onRemoveFile}
            />
          </div>

          <div style={{ display: getDisplayValue(1) }}>
            <PreviewForm form={previewForm} onFinish={handlePreview} />
          </div>

          <div style={{ display: getDisplayValue(2) }}>
            {previewData && (
              <Tables
                previewFormValues={previewFormValues}
                settingsData={settingsData}
                previewData={previewData}
                form={settingsForm}
              />
            )}
          </div>
        </Flex>
      </Container>
    </>
  );
};
